import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FiEye, FiEyeOff } from "react-icons/fi";
import Login_Right from "../../../assets/LOGO HRIS 6.png";
import LoginImg from "../../../assets/login-right.png";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [employeeNo, setEmployeeNo] = useState("");

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  };

  const getCookie = (name) => {
    const cookieArr = document.cookie.split("; ");
    for (let i = 0; i < cookieArr.length; i++) {
      const cookiePair = cookieArr[i].split("=");
      if (name === cookiePair[0]) {
        return decodeURIComponent(cookiePair[1]);
      }
    }
    return null;
  };

  useEffect(() => {
    const employeeNoCookie = getCookie("employee_no");
    if (employeeNoCookie) {
      setEmployeeNo(JSON.parse(employeeNoCookie));
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://backbritish.talentfort.live/v1/hris/user/userLogin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: email,
            password: password,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        const { user_token, ...userDetails } = data;

        // Save the token and user info to cookies
        setCookie("token", user_token, 7); // Cookie expires in 7 days        setCookie("user", JSON.stringify(userDetails), 7);
        setCookie("employee_no", userDetails.employee_no, 7); // Cookie expires in 7 days
        localStorage.setItem("token", user_token);


        // Navigate to the dashboard
        navigate("/home");
      } else {
        const errorData = await response.json();
        setLoginError(errorData.message || "Invalid email or password");
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setLoginError("An error occurred. Please try again.");
    }
  };

  return (
    <div className="flex gap-[10px] items-center overflow-hidden">
      <div className="w-1/2">
        <div className="ml-[15%]">
          <img
            src={Login_Right}
            alt="Logo"
            className="w-[250px] h-[250px] mb-5"
          />
        </div>

        <div className="ml-[20%]">
          <h2 className="text-[35px] font-semibold text-gray-800 mb-4">
            Login
          </h2>

          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Email input */}
            <div className="flex flex-col w-fit static">
              <label
                htmlFor="email"
                className="text-md font-semibold relative top-2 ml-[7px] px-[3px] bg-white rounded-[20px] w-fit"
              >
                Employee Number
              </label>
              <input
                id="email"
                placeholder="Enter your Employee ID"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border-black px-[10px] py-[11px] text-ml bg-white border-2 rounded-[54px] h-[56px] w-[512px] focus:outline-none placeholder:text-black/25"
              />
            </div>
            <div className="flex flex-col w-fit static">
              <label
                htmlFor="password"
                className="text-md font-semibold relative top-2 ml-[7px] px-[3px] bg-white rounded-[20px] w-fit z-50"
              >
                Password
              </label>
              <div className="relative">
                <input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="border-black px-[10px] py-[11px] text-ml bg-white border-2 rounded-[54px] h-[56px] w-[512px] focus:outline-none placeholder:text-black/25"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center pr-[10px] focus:outline-none"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? (
                    <FiEyeOff className="text-[#252C58] h-[20px] w-[20px]" />
                  ) : (
                    <FiEye className="text-[#252C58] h-[20px] w-[20px]" />
                  )}
                </button>
              </div>
            </div>
            <div className="mt-[20px] text-red-500">
              {loginError && <p>{loginError}</p>}
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input type="checkbox" id="remember" className="mr-2" />
                <label htmlFor="remember" className="text-sm text-gray-600">
                  Remember me
                </label>
              </div>
            </div>
            <div className="mt-[80px]">
              <button
                type="submit"
                className="w-[512px] bg-[#3D0B5E] text-[25px] text-white font-bold py-2 focus:outline-none rounded-[63px] mt-10"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="mr-2">
        <img
          src={LoginImg}
          alt="Login"
          className="w-[550px] h-[550px] rounded-b-[179px] ml-20 mr-20"
        />
      </div>
    </div>
  );
}

export default Login;